/**
 * Header is a React component that displays the header of the application.
 * It includes a title and a login button.
 * When the login button is clicked, the user is navigated to the "/login" route.
 * The component uses the useNavigate hook from react-router-dom to handle navigation.
 */
import React from "react";
import { useNavigate } from "react-router-dom";
import "../../App.css";

const Header = () => {
  const navigate = useNavigate();

  const handleLoginClick = () => {
    navigate("/login");
  };

  return (
    <div className="header">
      <h3>
        <a href="https://www.simatomic.com"> <img src="https://app.simatomic.com/logo2.png" alt="Simatomic logo" style={{ width: "12%", height: "auto", padding: "5px" }}/>
        </a>
      </h3>
      <button className="right-button" onClick={handleLoginClick}>
        Logout
      </button>
    </div>
  );
};

export default Header;
