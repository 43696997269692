import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Header from './components/Layout/Header';
import './App.css';

// Lazy load the components
// import Dashboard from './components/Dashboard/Dashboard';
// import WorkspaceProjects from './components/Pages/WorkspaceProjects';
// import Login from './components/User/Login';

const Dashboard = React.lazy(() => import('./components/Dashboard/Dashboard'));
const WorkspaceProjects = React.lazy(() => import('./components/Pages/WorkspaceProjects'));
const Login = React.lazy(() => import('./components/User/Login'));




const App = () => {
  return (
    <div className="App">
      <Router>
        <Header />
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/dashboard/:sectionId/:contentId/:workspaceId/:projectId" element={<Dashboard />} />
            <Route path="/dashboard/:sectionId/:workspaceId/:projectId" element={<Dashboard />} />
            {/* <Route path="/dashboard" element={<Navigate to="/dashboard/files/explorer" />} /> */}
            <Route path="/dashboard/experiment" element={<Navigate to="/dashboard/experiment/new_experiment" />} />
            <Route path="/dashboard/terminal" element={<Navigate to="/dashboard/terminal/terminal" />} />
            <Route path="/dashboard/files" element={<Navigate to="/dashboard/files/terminal" />} />
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<WorkspaceProjects />} />
          </Routes>
        </Suspense>
      </Router>
    </div>
  );
}

export default App;
